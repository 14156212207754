import { reserveService } from "../../services/ReserveService";
import { scheduleService } from "../../services/SchedulesService";
import { db } from "../../main";
import moment from "moment";
var moment_tz = require("moment-timezone");

export default {
  namespaced: true,
  state: {
    dateSelected: "",
    scheduleSelected: "",
    idReserve: "",
    userReserves: [],
    schedules: [],
    reservesByDate: [],
    placeSelected: "",
    idPlaceSelected: "",
    esFrotisCultivo: false,
    confirmDataReserve: {}
  },
  mutations: {
    resetReserveData: (state) => {
      state.dateSelected = "";
      state.scheduleSelected = "";
      state.idReserve = "";
      state.userReserves = [];
      state.placeSelected = "";
      state.idPlaceSelected = "";
      state.esFrotisCultivo = false;
      state.confirmDataReserve = {}
    },
    setReserveDate: (state, payload) => {
      state.dateSelected = payload;
    },
    setReservesByDate: (state, payload) => {
      let ordered = payload.sort((a, b) => {
        if (a.idSchedule > b.idSchedule) {
          return 1;
        }
        if (a.idSchedule < b.idSchedule) {
          return -1;
        }
        return 0;
      });
      state.reservesByDate = ordered;
    },
    setReserveSchedule: (state, payload) => {
      state.scheduleSelected = payload;
    },
    setIdReserve: (state, payload) => {
      state.idReserve = payload;
    },
    setUserReserves: (state, payload) => {
      state.userReserves = payload;
    },
    setSchedules: (state, payload) => {
      state.schedules = payload;
    },
    setPlaceSelected: (state, payload) => {
      state.placeSelected = payload.name;
      state.idPlaceSelected = payload.id;
    },
    setFrotisCultivo: (state, payload) => {
      state.esFrotisCultivo = payload;
    },
    setConfirmDataReserve: (state, payload) => {
      state.confirmDataReserve = payload;
    }
  },
  actions: {
    resetReserveData: (context) => {
      context.commit("resetReserveData");
    },
    setReserveDate: (context, payload) => {
      context.commit("setReserveDate", payload);
    },
    setReserveSchedule: (context, payload) => {
      context.commit("setReserveSchedule", payload);
    },
    setIdReserve: (context, payload) => {
      context.commit("setIdReserve", payload);
    },
    setUserReserves: (context, payload) => {
      context.commit("setUserReserves", payload);
    },
    setReservesByDate: (context, payload) => {
      context.commit("setReservesByDate", payload);
    },
    getReservesBySchedule: (context, payload) => {
      const date = payload;
      //conectarnos a firebase y traer las reservas enviando la fecha, agrupadas por horario
    },
    setPlaceSelected: (context, payload) => {
      context.commit("setPlaceSelected", payload);
    },
    setFrotisCultivo: (context, payload) => {
      context.commit("setFrotisCultivo", payload);
    },
    addReserve: (context) => {
      return new Promise((resolve, reject) => {
        const reserve = {
          date: context.state.dateSelected,
          schedule: context.state.scheduleSelected,
          document: context.rootState.users.userData.document,
          name: context.rootState.users.userData.name,
          lastname: context.rootState.users.userData.lastname,
          phone: context.rootState.users.userData.phone,
          email: context.rootState.users.userData.email,
          place: context.state.idPlaceSelected,
          eps: context.rootState.users.userData.eps,
          frotisCultivo: context.state.esFrotisCultivo,
        };

        reserveService
          .addReserve(reserve)
          .then(async (rta) => {       
            // if(reserveData.schedule){
            if(rta.id){
              let reserveData = await reserveService.getUserReserveData(rta.id);
              context.commit("setConfirmDataReserve",reserveData);
              await db.collection('log').add({
                date:  moment_tz().tz("America/Bogota").format(),
                event: 'Resolve true',
                document: reserve.document
              })
              return resolve(true);
            }
            await db.collection('log').add({
              date:  moment_tz().tz("America/Bogota").format(),
              event: 'Resolve false',
              document: reserve.document
            })
            return resolve(false)
          })
          .catch(async (err) => {
            await db.collection('log').add({
              date:  moment_tz().tz("America/Bogota").format(),
              event: 'Catch',
              document: reserve.document
            })
            reject(err);
          });
      });
    },
    updateReserve: (context) => {
      return new Promise((resolve, reject) => {
        const reserve = {
          date: context.state.dateSelected,
          schedule: context.state.scheduleSelected,
          document: context.rootState.users.userData.document,
          name: context.rootState.users.userData.name,
          lastname: context.rootState.users.userData.lastname,
          phone: context.rootState.users.userData.phone,
          email: context.rootState.users.userData.email,
          place: context.state.idPlaceSelected,
          eps: context.rootState.users.userData.eps,
          frotisCultivo: context.state.esFrotisCultivo,
        };

        reserveService
          .updateReserve(reserve, context.state.idReserve)
          .then(async (rta) => {         
            let reserveData = await reserveService.getUserReserveData(context.state.idReserve);
            await context.commit("setConfirmDataReserve",reserveData);
            return resolve(true);
          })
          .catch(async (err) => {
            await db.collection('log').add({
              date:  moment_tz().tz("America/Bogota").format(),
              event: 'Catch',
              document: reserve.document
            })
            reject(err);
          });
      });
    },
    deleteReserve: (context) => {
      return new Promise((resolve, reject) => {
        reserveService
          .deleteReserve(context.state.idReserve)
          .then((rta) => {
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getReservesByDatePrincipal: (context, payload) => {
      return new Promise(async (resolve, reject) => {
        // let hor = await scheduleService.getSchedulesPrincipal();
        var hor = await scheduleService.getSchedulesByPlace(
          context.state.placeSelected
        );

        reserveService
          .getReservesByDate(payload, context.state.idPlaceSelected)
          .then((reserves) => {
            let newReserves = reserves.map((e) => {
              let number = 0;
              hor.forEach((element) => {
                if (element.schedule == e.schedule) {
                  number = element.number;
                }
              });
              return { ...e, idSchedule: number };
            });
            let newData = hor.map(x => {
              let turnos = newReserves.filter(y => x.schedule == y.schedule);
              let disp = x.quotas-turnos.length
              return {...x,disponibles: disp,turnos: turnos}
            });

            let rta = []
            newData.forEach(element => {
              if(element.turnos.length > 0){
                rta.push(...element.turnos)
              }
              for (let index = 0; index < element.disponibles; index++) { 
                              
                rta.push({
                  idSchedule: element.number,
                  date: null,
                  document: '',
                  email: '',
                  frotisCultivo: false,
                  id: null,
                  lastname: '',
                  name: '',
                  phone: '',
                  place: element.turnos[index] ? element.turnos[index].place : '',
                  schedule: element.schedule
                }) 
              }
            });
            // context.commit("setReservesByDate", newReserves);
            context.commit("setReservesByDate", rta);
            resolve(newReserves);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getUserReserves: (context, payload) => {
      const docUser = payload;
      return new Promise((resolve, reject) => {
        reserveService
          .getUserReservesByDoc(docUser, context.state.idPlaceSelected)
          .then((reserves) => {
            resolve(reserves);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getAvailabilityReserves: (context, dateSelected) => {
      return new Promise(async (resolve, reject) => {

        //Actualizar campo fecha
        // db.collection("reserves")
        //   .where("date", ">=", moment(context.state.dateSelected).toISOString())
        //   .get()
        //   .then((querySnapshot)=>{
        //     querySnapshot.forEach((doc) => {
        //       // if(doc.data().document == '1098605678'){
        //         db.collection("reserves").doc(doc.id).update({date: moment_tz(doc.data().date).tz("America/Bogota").format('YYYY-MM-DD')})
        //       // }
              
        //     })
        //   })

        var hor = await scheduleService.getSchedulesByPlace(
          context.state.placeSelected
        );
        db.collection("reserves")
          .where("date", "==", moment_tz(context.state.dateSelected).tz("America/Bogota").format('YYYY-MM-DD'))
          .where("place", "==", context.state.idPlaceSelected)
          .onSnapshot((snap) => {
            let reservas = [];
            snap.forEach((el) => {
              reservas.push({ ...el.data() });
            });
            let disponibles = [];
            hor.forEach((el) => {
              let counter = 0;
              reservas.map((item) => {
                if (item.schedule === el.schedule) {
                  if (
                    item.document == context.rootState.users.userData.document
                  ) {
                  } else {
                    counter = counter + 1;
                  }
                }
              });
              if (
                el.number > 30 &&
                context.state.idPlaceSelected == "g1eAM9mSe9jazueGIqGQ"
              ) {
                // si es sede sotomayor y despues de las 9 no agrega el horario
              } else {
                let disabled = null;                
                if(el.disabledDates){
                  disabled = el.disabledDates.find(e => e == moment(context.state.dateSelected).format('YYYY-MM-DD'))
                }
                if(!disabled){
                  disponibles.push({
                    schedule: el.schedule,
                    state: el.quotas - counter
                  });
                }
                
              }
            });
            context.commit("setSchedules", disponibles);
            resolve(true);
          });
      });
    },
    getReservesExport: async (context, payload) => {
      //   return await scheduleService.getReservesByDateAndPlaceExport(
      //     payload.dateIni,
      //     payload.dateEnd,
      //     payload.place
      //   );
      return new Promise((resolve, reject) => {
        let dateIniSearched = moment_tz(payload.dateIni).tz("America/Bogota").format('YYYY-MM-DD');
        let dateEndSearched = moment_tz(payload.dateEnd).tz("America/Bogota").format('YYYY-MM-DD');
        if (payload.place) {
          db.collection("reserves")
            .where("date", ">=", dateIniSearched)
            .where("date", "<=", dateEndSearched)
            .where("place", "==", payload.place)
            .get()
            .then((snap) => {
              let rta = [];
              snap.forEach((snapHijo) => {
                let item = {
                  id: snapHijo.id,
                  cedula: snapHijo.data().document,
                  nombre: snapHijo.data().name + " " + snapHijo.data().lastname,
                  telefono: snapHijo.data().phone,
                  email: snapHijo.data().email,
                  fecha: snapHijo.data().date,
                  horario: snapHijo.data().schedule,
                  sede: snapHijo.data().place,
                };
                rta.push(item);
              });

              resolve(rta);
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          db.collection("reserves")
            .where("date", ">=", dateIniSearched)
            .where("date", "<=", dateEndSearched)
            .get()
            .then((snap) => {
              let rta = [];
              snap.forEach((snapHijo) => {
                let item = {
                  id: snapHijo.id,
                  cedula: snapHijo.data().document,
                  nombre: snapHijo.data().name + " " + snapHijo.data().lastname,
                  telefono: snapHijo.data().phone,
                  email: snapHijo.data().email,
                  fecha: snapHijo.data().date,
                  horario: snapHijo.data().schedule,
                  sede: snapHijo.data().place,
                };
                rta.push(item);
              });
              resolve(rta);
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    },
    onReserveFail: async (context,message) => {
      const data = {
        date: context.state.dateSelected,
        schedule: context.state.scheduleSelected,
        name: context.rootState.users.userData.name,
        lastname: context.rootState.users.userData.lastname,
        phone: context.rootState.users.userData.phone,
        email: context.rootState.users.userData.email,
        place: context.state.idPlaceSelected,
        eps: context.rootState.users.userData.eps,
        frotisCultivo: context.state.esFrotisCultivo,
      };
      await db.collection('log').add({
        date:  moment_tz().tz("America/Bogota").format(),
        event: 'Reserve Fail',
        message: message ? message : null,
        document: context.rootState.users.userData.document,
        data: data
      })
    }
  },
};
