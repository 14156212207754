import Vue from 'vue'
import Vuex from 'vuex'
import users from './modules/users'
import reserves from './modules/reserves'
import schedules from './modules/schedules'
import places from './modules/places';
import { firebaseApp } from '../main';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    layout: 'ReserveLayout',
    userLogged: localStorage.getItem('emailLogged'),
  },
  mutations: {
    setLayout: (state, payload) => {
      state.layout = payload;
    },
    setUserLogged: (state, payload) => {
      state.userLogged = payload;
      if (payload === null) {
        localStorage.removeItem('emailLogged');
      } else {
        localStorage.setItem('emailLogged', payload);
      }

    }
  },
  actions: {
    setLayout: (context, payload) => {
      context.commit('setLayout', payload)
    },
    signIn: (context, credentials) => {
      return new Promise((resolve, reject) => {
        firebaseApp
          .auth()
          .signInWithEmailAndPassword(credentials.email, credentials.password)
          .then((data) => {
            context.commit('setUserLogged', data.user.email);
            resolve(true);
          })
          .catch((error) => {
            console.log('Error al iniciar sesión', error);
            reject(error);
          });
      });
    },
    signOut: (context) => {
      return new Promise((resolve, reject) => {
        firebaseApp
          .auth()
          .signOut()
          .then((data) => {
            context.commit('setUserLogged', null);
            resolve(true);
          })
          .catch((error) => {
            console.log('Error al cerrar sesión', error);
            reject(error);
          });
      });
    },
    registerUser: (context, credentials) => {
      return new Promise((resolve, reject) => {
        firebaseApp
          .auth()
          .createUserWithEmailAndPassword(credentials.email, credentials.password)
          .then((data) => {
            context.commit('setUserLogged', data.user.email);
            resolve(true);
          })
          .catch((error) => {
            console.log('Error al iniciar sesión', error);
            reject(error);
          });
      });
    }
  },
  modules: {
    users,
    reserves,
    schedules,
    places
  }
})
