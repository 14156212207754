import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "StepPersonalInfo",
    // beforeEnter() {
    //   window.location.href = 'http://tomademuestras.alianzadx.com' // URL de la página externa a la que deseas redirigir
    // }
    component: () => import("../views/StepPersonalInfo.vue"),
  },
  {
    path: "/stepDate",
    name: "StepDate",
    component: () => import("../views/StepDate.vue"),
  },
  {
    path: "/stepSchedule",
    name: "StepSchedule",
    component: () => import("../views/StepSchedule.vue"),
  },
  {
    path: "/stepConfirmReserve",
    name: "StepConfirmReserve",
    component: () => import("../views/StepConfirmReserve.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("../views/Admin.vue"),
  },
  {
    path: "/admin/export-excel",
    name: "ExportExcel",
    component: () => import("../views/ExportExcel.vue"),
  },
  {
    path: "/admin/reports",
    name: "Reports",
    component: () => import("../views/Reports.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
