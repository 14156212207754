
import { scheduleService } from '../../services/SchedulesService'

export default {
    namespaced: true,
    state: {
        schedules: [],
    },
    mutations: {
        setSchedules: (state, payload) => {
            state.schedules = payload;
        }
    },
    actions: {
        getSchedules: (context) => {
            return new Promise((resolve, reject) => {
                scheduleService.getSchedules()
                    .then(rta => {
                        context.commit('setSchedules', rta);
                        resolve(true);
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
        },
        getSchedulesByPlace: (context,payload) => {
            return new Promise((resolve, reject) => {
                scheduleService.getSchedulesByPlace(payload)
                    .then(rta => {
                        context.commit('setSchedules', rta);
                        resolve(rta);
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
        }
    },
    getters: {

    }
}