import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import moment from 'moment'

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth'

import './main.scss'

// import plugin
import VueToastr from "vue-toastr";
// use plugin
Vue.use(VueToastr, {
  defaultTimeout: 2500,
  defaultPosition: "toast-top-center",
  defaultStyle: { "margin-top": "40vh" }
});

moment.tz.setDefault("America/Bogota");
moment.locale('es');

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

//Pruebas
// var firebaseConfig = {
//   apiKey: "AIzaSyD16VWyIoxQUFGyKVo_-54r2JDv9gKt-2Q",
//   authDomain: "mailer-bbdb3.firebaseapp.com",
//   databaseURL: "https://mailer-bbdb3.firebaseio.com",
//   projectId: "mailer-bbdb3",
//   storageBucket: "mailer-bbdb3.appspot.com",
//   messagingSenderId: "1092297851288",
//   appId: "1:1092297851288:web:7f4408a74376bbfe7d2bed"
// };

//Productivo
var firebaseConfig = {
  apiKey: "AIzaSyAVbb6jnxaqSSLeqWfIeGSYp-MdNQeobwY",
  authDomain: "ad-control-aforo.firebaseapp.com",
  projectId: "ad-control-aforo",
  storageBucket: "ad-control-aforo.appspot.com",
  messagingSenderId: "326514790435",
  appId: "1:326514790435:web:b7e4e535cc2b28518aa196"
};


// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

Object.defineProperty(Vue.prototype, '$moment', { value: moment })

export const db = firebase.firestore();
export const firebaseApp = app;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
