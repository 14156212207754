import { db } from '../main'

export const placeService = {
    getPlaces: () => {
        return new Promise((resolve, reject) => {
            db.collection('places')
                .orderBy('name', 'asc')
                .get()
                .then(snap => {
                    var places = [];
                    snap.forEach(el => {
                        let item = el.data();
                        if(item.active === true){
                            places.push({ id: el.id, ...el.data() })
                        }
                    })
                    resolve(places);
                })
                .catch(err => {
                    reject(err);
                })
        })        
    },
    getPlaceData: (placeId) => {
        return new Promise((resolve, reject) => {
            db.collection('places')
                .doc(placeId)
                .get()
                .then(doc => {
                    resolve(doc.data());
                })
                .catch(err => {
                    reject(err);
                })
        })   
    }
}