import { db } from '../main'
var moment_tz = require("moment-timezone");

export const userService = {
    getUserByDoc: (docUser) => {
        return new Promise((resolve, reject) => {
            db.collection('users')
                .where('document', '==', docUser)
                .get()
                .then(snap => {
                    let rta = {};
                    if (snap.empty == true) {
                        resolve(rta);
                    }
                    snap.forEach(snapHijo => {
                        rta = {
                            id: snapHijo.id,
                            document: snapHijo.data().document,
                            name: snapHijo.data().name,
                            lastname: snapHijo.data().lastname,
                            phone: snapHijo.data().phone,
                            email: snapHijo.data().email,
                            eps: snapHijo.data().eps
                        };
                    })
                    resolve(rta);
                })
        })
    },
    createUser: (data) => {
        return new Promise((resolve, reject) => {
            db.collection('users').add({
                document: data.document,
                name: data.name,
                lastname: data.lastname,
                phone: data.phone,
                email: data.email,
                survey: data.survey,
                eps: data.eps,
                created_at: moment_tz()
                    .tz("America/Bogota")
                    .format(),
            }).then(async rta => {
                await db.collection('log').add({
                    date:  moment_tz().tz("America/Bogota").format(),
                    event: 'User Created',
                    data: data
                })
                resolve(rta);
            }).catch(err => {
                reject(err)
            })
        })
    },
    updateUser: (idUser, data) => {
        return new Promise((resolve, reject) => {
            db.collection('users').doc(idUser).update({
                document: data.document,
                name: data.name,
                lastname: data.lastname,
                phone: data.phone,
                email: data.email,
                survey: data.survey,
                eps: data.eps
            }).then(async rta => {
                await db.collection('log').add({
                    date:  moment_tz().tz("America/Bogota").format(),
                    event: 'User Updated',
                    data: data
                })
                resolve(rta);
            }).catch(err => {
                reject(err)
            })
        })
    },
    deleteUser: (idUser) => {
        return new Promise((resolve, reject) => {
            db.collection('users').doc(idUser).delete().then(rta => {
                resolve(rta);
            }).catch(err => {
                reject(err)
            })
        })
    },
    getLogRegisters: () => {
        return new Promise((resolve, reject) => {
            console.log('consultando')
            db.collection('log')
                .orderBy('date', 'desc')
                .get()
                .then(snap => {
                    var logs = [];
                    snap.forEach(el => {
                        logs.push(el.data())
                    })
                    resolve(logs);
                })
                .catch(err => {
                    reject(err);
                })
        }) 
    }
}