
import { placeService } from '../../services/PlaceService'

export default {
    namespaced: true,
    state: {
        places: [],
    },
    mutations: {
        setPlaces: (state, payload) => {
            state.places = payload;
        }
    },
    actions: {
        getPlaces: (context) => {
            return new Promise((resolve, reject) => {
                placeService.getPlaces()
                    .then(rta => {
                        // Para desactivar sedes, por el momento asi
                        // let actives = rta.filter(el => {
                        //     if(el.name == 'Sede CEC'){
                        //         return false;
                        //     }
                        //     return true
                        // })
                        // context.commit('setPlaces', actives);
                        context.commit('setPlaces',rta)
                        resolve(true);
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
        }
    },
    getters: {

    }
}