<template>
  <b-container>
    <b-row>
      <b-col cols="12" sm="12" md="12" align-self="center">
        <b-container>
          <b-row>
            <b-col class="text-center">
              <b-card
                header-bg-variant="primary"
                header-text-variant="white"
                align="center"
                id="card-aforo"
              >
                <template #header>
                  <h6 class="mb-0">
                    Administrador - Agendamiento de Citas Laboratorio Clínico
                  </h6>
                </template>

                <b-row>
                  <b-col>
                    <b-button
                      v-if="isLogged"
                      :pressed="true"
                      style="
                    background-color: #00a341;
                  "
                      to="/admin"
                      >Volver a Inicio</b-button
                    >
                  </b-col>
                  <b-col>
                    <b-img
                      src="https://www.alianzadiagnostica.com/images/logo-img.png"
                      fluid
                      class="mt-1 mb-3 text-center"
                      center
                    ></b-img>
                  </b-col>
                  <b-col>
                    <b-button
                      v-if="isLogged"
                      :pressed="true"
                      style="
                    background-color: #00a341;
                  "
                      @click="logout"
                      >Cerrar Sesión</b-button
                    >
                  </b-col>
                </b-row>
                <router-view />
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["userLogged"]),
    isLogged() {
      if (this.userLogged) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {},
  methods: {
    ...mapActions(["signOut"]),
    logout() {
      this.signOut()
        .then((rta) => {
          this.$router.replace("/login");
        })
        .catch((err) => {
          console.log("error iniciando sesion", err);
        });
    },
  },
};
</script>
