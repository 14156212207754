import moment from "moment";
import { db } from "../main";
import { placeService } from "./PlaceService";
var moment_tz = require("moment-timezone");

export const reserveService = {
  addReserve: (data) => {
    return new Promise((resolve, reject) => {
      let dateFormat = moment_tz(data.date).tz("America/Bogota").format('YYYY-MM-DD');
      db.collection("reserves")
        .add({
          date: dateFormat,
          schedule: data.schedule,
          document: data.document,
          name: data.name,
          lastname: data.lastname,
          phone: data.phone,
          email: data.email,
          place: data.place,
          eps: data.eps,
          frotisCultivo: data.frotisCultivo,
          created_at: moment_tz()
            .tz("America/Bogota")
            .format(),
          updated_at: moment_tz()
            .tz("America/Bogota")
            .format(),
        })
        .then(async (rta) => {  
          console.log('resolve',rta)       
          resolve(rta);
        })
        .catch((err) => {
          console.log('reject',err)
          reject(err);
        });
    });
  },
  updateReserve: (data, id) => {
    return new Promise((resolve, reject) => {
      let dateFormat = moment_tz(data.date).tz("America/Bogota").format('YYYY-MM-DD');
      db.collection("reserves")
        .doc(id)
        .update({
          date: dateFormat,
          schedule: data.schedule,
          document: data.document,
          name: data.name,
          lastname: data.lastname,
          phone: data.phone,
          email: data.email,
          place: data.place,
          eps: data.eps,
          frotisCultivo: data.frotisCultivo,
          updated_at: moment_tz()
            .tz("America/Bogota")
            .format(),
        })
        .then(async (rta) => {
          resolve(rta);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateReserveFrotis: (data, id) => {
    return new Promise((resolve, reject) => {
      db.collection("reserves")
        .doc({
          document: data.document,
          date: moment(data.date).toISOString(),
          place: data.place,
        })
        .update({
          date: moment(data.date).toISOString(),
          schedule: data.schedule,
          document: data.document,
          name: data.name,
          lastname: data.lastname,
          phone: data.phone,
          email: data.email,
          place: data.place,
          eps: data.eps,
          frotisCultivo: data.frotisCultivo,
        })
        .then((rta) => {
          resolve(rta);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteReserve: (id) => {
    return new Promise((resolve, reject) => {
      db.collection("reserves")
        .doc(id)
        .delete()
        .then((rta) => {
          resolve(rta);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getReservesByDate: (date, place) => {
    return new Promise(async (resolve, reject) => {
      let snap = await db.collection("reserves")
        .where("date", "==", moment_tz(date).tz("America/Bogota").toISOString())
        .where("place", "==", place)
        .get();
      
      let newSnap = await db.collection("reserves")
        .where("date", "==", moment_tz(date).tz("America/Bogota").format('YYYY-MM-DD'))
        .where("place", "==", place)
        .get();

      let rta = [];
      snap.forEach((snapHijo) => {
        let item = {
          id: snapHijo.id,
          document: snapHijo.data().document,
          name: snapHijo.data().name + " " + snapHijo.data().lastname,
          lastname: snapHijo.data().lastname,
          phone: snapHijo.data().phone,
          email: snapHijo.data().email,
          date: snapHijo.data().date,
          schedule: snapHijo.data().schedule,
          place: snapHijo.data().place,
          eps: snapHijo.data().eps,
          frotisCultivo: snapHijo.data().frotisCultivo,
        };
        rta.push(item);
      });

      newSnap.forEach((snapHijo) => {
        let item = {
          id: snapHijo.id,
          document: snapHijo.data().document,
          name: snapHijo.data().name + " " + snapHijo.data().lastname,
          lastname: snapHijo.data().lastname,
          phone: snapHijo.data().phone,
          email: snapHijo.data().email,
          date: snapHijo.data().date,
          schedule: snapHijo.data().schedule,
          place: snapHijo.data().place,
          eps: snapHijo.data().eps,
          frotisCultivo: snapHijo.data().frotisCultivo,
        };
        rta.push(item);
      });
      resolve(rta);
    });
  },
  getUserReservesByDoc: (docUser, place) => {
    return new Promise((resolve, reject) => {
      let today = moment_tz().tz("America/Bogota").format('YYYY-MM-DD');
      db.collection("reserves")
        .where("document", "==", docUser)
        .where("date", ">", today)
        .where("place", "==", place)
        .get()
        .then((snap) => {
          let rta = [];
          snap.forEach((snapHijo) => {
            let item = {
              id: snapHijo.id,
              date: snapHijo.data().date,
              schedule: snapHijo.data().schedule,
              place: snapHijo.data().place,
              eps: snapHijo.data().eps,
              frotisCultivo: snapHijo.data().frotisCultivo,
            };
            rta.push(item);
          });
          resolve(rta);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getUserReserveData: (idReserve) => {
    return new Promise((resolve, reject) => {
      db.collection("reserves").doc(idReserve)
        .get()
        .then(async (doc) => {   
          const res = doc.data();
          const placeData = await placeService.getPlaceData(res.place)
          let responseData = {
            date: moment(res.date).format('YYYY-MM-DD'),
            schedule: res.schedule,
            placeName: `${placeData.name} (${placeData.adress})`
          } 
          resolve(responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};
