<template>
  <div id="app">
    <component :is="layout"></component>
  </div>
</template>
<script>
import AdminLayout from "./components/layouts/AdminLayout";
import ReserveLayout from "./components/layouts/ReserveLayout";
export default {
  components: { AdminLayout, ReserveLayout },
  data() {
    return {
      // layout: this.$store.state.layout,
    };
  },
  computed: {
    layout() {
      return this.$store.state.layout;
    },
  },
};
</script>
<style lang="scss">
</style>
