import { db } from '../main'
var moment_tz = require('moment-timezone');
import schedulesByPlace from '../resources/schedules-places';

export const scheduleService = {
    // Esta función trae los horarios de la base de datos
    // Se deja de usar y se empieza a usar en intervalos de 6 minutos como la principal
    // Para esto se crea la otra función
    getSchedules: (place) => {
        return new Promise((resolve, reject) => {
            // let schedulesLocal = JSON.parse(localStorage.getItem("schedules-local"));
            // if (schedulesLocal) {
            //     // console.log('schedules desde local')
            //     resolve(schedulesLocal);
            // } else {
                db.collection('schedules')
                    .orderBy('number', 'asc')
                    .get()
                    .then(snap => {
                        var horarios = [];
                        snap.forEach(el => {
                            horarios.push({ ...el.data() })
                        })
                        localStorage.setItem("schedules-local", JSON.stringify(horarios));
                        resolve(JSON.parse(localStorage.getItem("schedules-local")));
                    })
                    .catch(err => {
                        reject(err);
                    })
            // }

        });
    },    
    getSchedulesByPlace: (placeName) => {
        return new Promise((resolve,reject) => {
            var horarios = [];
            let schedulesPlace = schedulesByPlace.find(e => e.name == placeName);
            horarios = schedulesPlace.schedules;
            // console.log(horarios)
            resolve(horarios);
        })
    }   
}