export default [
  {
    name: "Principal (Alianza Diagnóstica S.A.)",
    active: false,
    schedules: [
      {
        number: 1,
        quotas: "3",
        schedule: "06:00 AM - 06:06 AM",
      },
      {
        number: 2,
        quotas: "3",
        schedule: "06:06 AM - 06:12 AM",
      },
      {
        number: 3,
        quotas: "3",
        schedule: "06:12 AM - 06:18 AM",
      },
      {
        number: 4,
        quotas: "3",
        schedule: "06:18 AM - 06:24 AM",
      },
      {
        number: 5,
        quotas: "3",
        schedule: "06:24 AM - 06:30 AM",
      },
      {
        number: 6,
        quotas: "3",
        schedule: "06:30 AM - 06:36 AM",
      },
      {
        number: 7,
        quotas: "3",
        schedule: "06:36 AM - 06:42 AM",
      },
      {
        number: 8,
        quotas: "3",
        schedule: "06:42 AM - 06:48 AM",
      },
      {
        number: 9,
        quotas: "3",
        schedule: "06:48 AM - 06:54 AM",
      },
      {
        number: 10,
        quotas: "3",
        schedule: "06:54 AM - 07:00 AM",
      },
      {
        number: 11,
        quotas: "3",
        schedule: "07:00 AM - 07:06 AM",
      },
      {
        number: 12,
        quotas: "3",
        schedule: "07:06 AM - 07:12 AM",
      },
      {
        number: 13,
        quotas: "3",
        schedule: "07:12 AM - 07:18 AM",
      },
      {
        number: 14,
        quotas: "3",
        schedule: "07:18 AM - 07:24 AM",
      },
      {
        number: 15,
        quotas: "3",
        schedule: "07:24 AM - 07:30 AM",
      },
      {
        number: 16,
        quotas: "3",
        schedule: "07:30 AM - 07:36 AM",
      },
      {
        number: 17,
        quotas: "3",
        schedule: "07:36 AM - 07:42 AM",
      },
      {
        number: 18,
        quotas: "3",
        schedule: "07:42 AM - 07:48 AM",
      },
      {
        number: 19,
        quotas: "3",
        schedule: "07:48 AM - 07:54 AM",
      },
      {
        number: 20,
        quotas: "3",
        schedule: "07:54 AM - 08:00 AM",
      },
      {
        number: 21,
        quotas: "3",
        schedule: "08:00 AM - 08:06 AM",
      },
      {
        number: 22,
        quotas: "3",
        schedule: "08:06 AM - 08:12 AM",
      },
      {
        number: 23,
        quotas: "3",
        schedule: "08:12 AM - 08:18 AM",
      },
      {
        number: 24,
        quotas: "3",
        schedule: "08:18 AM - 08:24 AM",
      },
      {
        number: 25,
        quotas: "3",
        schedule: "08:24 AM - 08:30 AM",
      },
      {
        number: 26,
        quotas: "3",
        schedule: "08:30 AM - 08:36 AM",
      },
      {
        number: 27,
        quotas: "3",
        schedule: "08:36 AM - 08:42 AM",
      },
      {
        number: 28,
        quotas: "3",
        schedule: "08:42 AM - 08:48 AM",
      },
      {
        number: 29,
        quotas: "3",
        schedule: "08:48 AM - 08:54 AM",
      },
      {
        number: 30,
        quotas: "3",
        schedule: "08:54 AM - 09:00 AM",
      },
      {
        number: 31,
        quotas: "3",
        schedule: "09:00 AM - 09:06 AM",
      },
      {
        number: 32,
        quotas: "3",
        schedule: "09:06 AM - 09:12 AM",
      },
      {
        number: 33,
        quotas: "3",
        schedule: "09:12 AM - 09:18 AM",
      },
      {
        number: 34,
        quotas: "3",
        schedule: "09:18 AM - 09:24 AM",
      },
      {
        number: 35,
        quotas: "3",
        schedule: "09:24 AM - 09:30 AM",
      },
      {
        number: 36,
        quotas: "3",
        schedule: "09:30 AM - 09:36 AM",
      },
      {
        number: 37,
        quotas: "3",
        schedule: "09:36 AM - 09:42 AM",
      },
      {
        number: 38,
        quotas: "3",
        schedule: "09:42 AM - 09:48 AM",
      },
      {
        number: 39,
        quotas: "3",
        schedule: "09:48 AM - 09:54 AM",
      },
      {
        number: 40,
        quotas: "3",
        schedule: "09:54 AM - 10:00 AM",
      },
    ],
  },
  {
    name: "Sede CEC",
    active: false,
    schedules: [
      {
        number: 1,
        quotas: "1",
        schedule: "06:00 AM - 06:06 AM",
      },
      {
        number: 2,
        quotas: "1",
        schedule: "06:06 AM - 06:12 AM",
      },
      {
        number: 3,
        quotas: "1",
        schedule: "06:12 AM - 06:18 AM",
      },
      {
        number: 4,
        quotas: "1",
        schedule: "06:18 AM - 06:24 AM",
      },
      {
        number: 5,
        quotas: "1",
        schedule: "06:24 AM - 06:30 AM",
      },
      {
        number: 6,
        quotas: "1",
        schedule: "06:30 AM - 06:36 AM",
      },
      {
        number: 7,
        quotas: "1",
        schedule: "06:36 AM - 06:42 AM",
      },
      {
        number: 8,
        quotas: "1",
        schedule: "06:42 AM - 06:48 AM",
      },
      {
        number: 9,
        quotas: "1",
        schedule: "06:48 AM - 06:54 AM",
      },
      {
        number: 10,
        quotas: "1",
        schedule: "06:54 AM - 07:00 AM",
      },
      {
        number: 11,
        quotas: "1",
        schedule: "07:00 AM - 07:06 AM",
      },
      {
        number: 12,
        quotas: "1",
        schedule: "07:06 AM - 07:12 AM",
      },
      {
        number: 13,
        quotas: "1",
        schedule: "07:12 AM - 07:18 AM",
      },
      {
        number: 14,
        quotas: "1",
        schedule: "07:18 AM - 07:24 AM",
      },
      {
        number: 15,
        quotas: "1",
        schedule: "07:24 AM - 07:30 AM",
      },
      {
        number: 16,
        quotas: "1",
        schedule: "07:30 AM - 07:36 AM",
      },
      {
        number: 17,
        quotas: "1",
        schedule: "07:36 AM - 07:42 AM",
      },
      {
        number: 18,
        quotas: "1",
        schedule: "07:42 AM - 07:48 AM",
      },
      {
        number: 19,
        quotas: "1",
        schedule: "07:48 AM - 07:54 AM",
      },
      {
        number: 20,
        quotas: "1",
        schedule: "07:54 AM - 08:00 AM",
      },
      {
        number: 21,
        quotas: "1",
        schedule: "08:00 AM - 08:06 AM",
      },
      {
        number: 22,
        quotas: "1",
        schedule: "08:06 AM - 08:12 AM",
      },
      {
        number: 23,
        quotas: "1",
        schedule: "08:12 AM - 08:18 AM",
      },
      {
        number: 24,
        quotas: "1",
        schedule: "08:18 AM - 08:24 AM",
      },
      {
        number: 25,
        quotas: "1",
        schedule: "08:24 AM - 08:30 AM",
      },
      {
        number: 26,
        quotas: "1",
        schedule: "08:30 AM - 08:36 AM",
      },
      {
        number: 27,
        quotas: "1",
        schedule: "08:36 AM - 08:42 AM",
      },
      {
        number: 28,
        quotas: "1",
        schedule: "08:42 AM - 08:48 AM",
      },
      {
        number: 29,
        quotas: "1",
        schedule: "08:48 AM - 08:54 AM",
      },
      {
        number: 30,
        quotas: "1",
        schedule: "08:54 AM - 09:00 AM",
      },
    ],
  },
  {
    name: "Girón (UAB Girón)",
    active: true,
    schedules: [
      {
        number: 1,
        quotas: "13",
        schedule: "06:00 AM",
      },
      {
        number: 2,
        quotas: "13",
        schedule: "06:30 AM",
      },
      {
        number: 3,
        quotas: "13",
        schedule: "07:00 AM",
      },
      {
        number: 4,
        quotas: "13",
        schedule: "07:30 AM",
      },
      {
        number: 5,
        quotas: "13",
        schedule: "08:00 AM",
      },
      {
        number: 6,
        quotas: "13",
        schedule: "08:30 AM",
      },
      {
        number: 7,
        quotas: "13",
        schedule: "09:00 AM",
      },
      {
        number: 8,
        quotas: "13",
        schedule: "09:30 AM",
      }
    ],
  },
  {
    name: "Cañaveral",
    active: true,
    schedules: [
      {
        number: 1,
        quotas: "13",
        schedule: "06:00 AM",
      },
      {
        number: 2,
        quotas: "13",
        schedule: "06:30 AM",
      },
      {
        number: 3,
        quotas: "13",
        schedule: "07:00 AM",
      },
      {
        number: 4,
        quotas: "13",
        schedule: "07:30 AM",
      },
      {
        number: 5,
        quotas: "13",
        schedule: "08:00 AM",
      },
      {
        number: 6,
        quotas: "13",
        schedule: "08:30 AM",
      },
      {
        number: 7,
        quotas: "13",
        schedule: "09:00 AM",
      },
      {
        number: 8,
        quotas: "13",
        schedule: "09:30 AM",
      }
    ],
  },
  {
    name: "Bucarica",
    active: true,
    schedules: [
      {
        number: 1,
        quotas: "13",
        schedule: "06:00 AM",
      },
      {
        number: 2,
        quotas: "13",
        schedule: "06:30 AM",
      },
      {
        number: 3,
        quotas: "13",
        schedule: "07:00 AM",
      },
      {
        number: 4,
        quotas: "13",
        schedule: "07:30 AM",
      },
      {
        number: 5,
        quotas: "13",
        schedule: "08:00 AM",
      },
      {
        number: 6,
        quotas: "13",
        schedule: "08:30 AM",
      },
      {
        number: 7,
        quotas: "13",
        schedule: "09:00 AM",
      },
      {
        number: 8,
        quotas: "13",
        schedule: "09:30 AM",
      }
    ],
  },
  {
    name: "CISS - Centro Integral de Servicios de Salud",
    active: true,
    schedules: [
      {
        number: 1,
        quotas: "35",
        schedule: "06:00 AM",
      },
      {
        number: 2,
        quotas: "35",
        schedule: "06:30 AM",
      },
      {
        number: 3,
        quotas: "35",
        schedule: "07:00 AM",
      },
      {
        number: 4,
        quotas: "35",
        schedule: "07:30 AM",
      },
      {
        number: 5,
        quotas: "35",
        schedule: "08:00 AM",
      },
      {
        number: 6,
        quotas: "35",
        schedule: "08:30 AM",
      },
      {
        number: 7,
        quotas: "35",
        schedule: "09:00 AM",
      },
      {
        number: 8,
        quotas: "35",
        schedule: "09:30 AM",
      }
    ],
  },
  {
    name: "Barrancabermeja",
    active: true,
    schedules: [
      {
        number: 1,
        quotas: "14",
        schedule: "06:00 AM",
      },
      {
        number: 2,
        quotas: "14",
        schedule: "06:30 AM",
      },
      {
        number: 3,
        quotas: "14",
        schedule: "07:00 AM",
      },
      {
        number: 4,
        quotas: "14",
        schedule: "07:30 AM",
      },
      {
        number: 5,
        quotas: "14",
        schedule: "08:00 AM",
      },
      {
        number: 6,
        quotas: "14",
        schedule: "08:30 AM",
        disabledDates: [
          '2022-12-30'
        ]
      },
      {
        number: 7,
        quotas: "14",
        schedule: "09:00 AM",
        disabledDates: [
          '2022-12-30'
        ]
      },
      {
        number: 8,
        quotas: "14",
        schedule: "09:30 AM",
        disabledDates: [
          '2022-12-30'
        ]
      }
    ],
  },
];
