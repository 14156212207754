import { userService } from '../../services/UserService'

export default {
    namespaced: true,
    state: {
        userData: {
            document: '',
            name: '',
            lastname: '',
            phone: '',
            email: '',
            eps: '',
            id: ''
        },
        preguntas: [
            {
                id: 1,
                text:
                    "¿Ha presentado fiebre cuantificada mayor o igual a 38°C?",
                respuesta: "",
            },
            { id: 2, text: "¿Tiene tos o dificultad respiratoria?", respuesta: "" },
            {
                id: 3,
                text: "¿Ha presentado perdida del sentido del gusto o del olfato en los últimos 14 días?",
                respuesta: "",
            },
            {
                id: 4,
                text:
                    "¿Tiene dolor de garganta?",
                respuesta: "",
            },
            {
                id: 5,
                text: "¿Tiene fatiga? ",
                respuesta: "",
            },
            {
                id: 6,
                text: "¿Vive con alguien sospechoso confirmado para COVID-19?",
                respuesta: "",
            },
            {
                id: 7,
                text: "¿Ha presentado la enfermedad COVID – 19?",
                respuesta: "",
            },
            {
                id: 8,
                text: "En caso de haber presentado la enfermedad, ¿Cuántos días han transcurrido después del inicio de los síntomas?",
                respuesta: "",
            },
            {
                id: 9,
                text: "¿En los últimos 14 dias ha tenido contacto estrecho por mas de 15 min, a menos de 2 metros de distancia y sin usar tapabocas; con alguien sospechoso o confirmado para COVID - 19?",
                respuesta: "",
            },
            {
                id: 10,
                text: "¿Es trabajador del área de la salud?",
                respuesta: "",
            },
            // {
            //     id: 11,
            //     text: "Digitar la temperatura registrada",
            //     respuesta: "",
            // },
        ],
    },
    mutations: {
        setUserData: (state, payload) => {
            state.userData.document = payload.document;
            state.userData.name = payload.name;
            state.userData.lastname = payload.lastname;
            state.userData.phone = payload.phone;
            state.userData.email = payload.email;
            state.userData.id = payload.id;
            state.userData.eps = payload.eps;
        },
        resetUserData: (state) => {
            state.userData.document = '';
            state.userData.name = '';
            state.userData.lastname = '';
            state.userData.phone = '';
            state.userData.email = '';
            state.userData.id = '';
            state.userData.eps = '';
        }
    },
    actions: {
        setUserData: (context, payload) => {
            context.commit('setUserData', payload)
        },
        resetUserData: (context) => {
            context.commit('resetUserData')
        },
        createUser: (context) => {
            return new Promise((resolve, reject) => {
                const data = {
                    document: context.state.userData.document,
                    name: context.state.userData.name,
                    lastname: context.state.userData.lastname,
                    phone: context.state.userData.phone,
                    email: context.state.userData.email,
                    survey: context.state.preguntas,
                    eps: context.state.userData.eps
                }
                userService.createUser(data).then(rta => {
                    resolve(rta);
                }).catch(async err => {
                    await db.collection('log').add({
                        date:  moment_tz().tz("America/Bogota").format(),
                        event: 'Create User Fail',
                        message: 'err',
                        document: context.state.userData.document,
                        data: data
                    })
                    reject('Error al crear usuario.', err)
                })
            })
        },
        getUser: (context, docUser) => {
            return new Promise((resolve, reject) => {
                userService.getUserByDoc(docUser).then(rta => {
                    context.commit('setUserData', rta)
                    resolve(rta);
                }).catch(err => {
                    reject('Error al consultar usuario.', err);
                })
            })
        },
        updateUser: (context) => {
            return new Promise((resolve, reject) => {
                userService.updateUser(context.state.userData.id, {
                    document: context.state.userData.document,
                    name: context.state.userData.name,
                    lastname: context.state.userData.lastname,
                    phone: context.state.userData.phone,
                    email: context.state.userData.email,
                    survey: context.state.preguntas,
                    eps: context.state.userData.eps
                }).then(rta => {
                    resolve(rta);
                }).catch(err => {
                    // console.log(err)
                    reject('Error al actualizar usuario', err)
                })
            })
        },
        deleteUser: (context) => {
            return new Promise((resolve, reject) => {
                userService.deleteUser(context.state.userData.id)
                .then(rta => {
                    resolve(rta);
                })
                .catch(err => {
                    // console.log(err)
                    reject('Error al actualizar usuario', err)
                })
            })
        },
        getLog: (context) => {
            return new Promise((resolve, reject) => {
                userService.getLogRegisters()
                .then(rta => {
                    resolve(rta);
                })
                .catch(err => {
                    reject('Error al consultar logs', err)
                })
            })
        }
    },
    getters: {}
}